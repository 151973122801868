import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import Container from "../Core/Container";
import breakpoints from "../../styles/breakpoints";

const HeroContent = styled.div`
  padding: 1.25rem 0;
`;

const Image = styled(Img)`
  max-height: 350px;

  @media only screen and (min-width: ${breakpoints.sm}) {
    max-height: 400px;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    max-height: 500px;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    max-height: 700px;
  }
`;

const Hero = ({image}) => {
    return (
      <Container>
        <HeroContent>
          <Image fluid={image.asset.fluid} loading="eager" critical="true" />
        </HeroContent>
      </Container>
    );
};

export default Hero;