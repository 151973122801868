import React, {Component} from "react";
import styled from "styled-components";
import Container from "../Core/Container";
import {Row, Col} from "styled-bootstrap-grid";
import breakpoints from "../../styles/breakpoints";
import Section from "../Core/Section"

//imports
import maplibregl from '../../assets/javascript/maplibre.js'
import  '../../assets/styles/mapboxgl.css'


const Styles = styled.div`
  height: 26.25rem;

  @media only screen and (min-width: ${breakpoints.desktop}) {
    height: 36.25rem;
  }

  .maplibregl-ctrl {
    &.mapboxgl-ctrl-attrib {
      background-color: transparent;
    }
    .maplibregl-ctrl-attrib-button {
      display: none;
    }

    .maplibregl-ctrl-attrib-inner {
      font-size: 0.5rem;
      @media only screen and (min-width: ${breakpoints.desktop}) {
        font-size: 0.625rem;
      }
    }
  }
`;

class Map extends Component {

  constructor(params) {
    super(params);
    this.ref = React.createRef()
}

  createMap = () => {
    let app = this, 
        map = app.ref.current,
        coords = app.props.location

    app.mapCore = new maplibregl.Map({
        container: map,
        style: 'https://maps.geo.eu-west-1.amazonaws.com/maps/v0/maps/esrinav/style-descriptor?key=v1.public.eyJqdGkiOiI3MzZlYzI0ZS01NDA5LTRlMTAtOGZiMS01MjRhMTJhOTk1NDYifU1JoF2LfT4zW6B6vybaiYCh7KpG4zATM4r3rZm6udel1nPEz1sI-Ua8gP35gC-ursYNtX0mrPAgaHbCRDWzdGlVAeBjl09CBJgleA4TfahGyYnpGgKmSbyN4C6vAZ3-WpgrZYNguOT4VC9gbOkSS66Lhf68_N7uk-0Nv1Z5ojX_MZlcnrtE2NHAKirN3f3Qa4LXLu1HhxAgpL_laHOg9iBkQ8yC4LkpjEO5Kcd6bEHlc19K9o4WdUdDNqIpwqEyCquF6_p1EBeScrOR7lUdIb9n7VWfakjBAnIKYiuN7TVKxyP3b909PCleUC9yhwoVX10Wl4k8agl7dEtWf-AWA2o.ZGQzZDY2OGQtMWQxMy00ZTEwLWIyZGUtOGVjYzUzMjU3OGE4',
        center: coords,
        zoom: 11
    });
    app.mapCore.addControl(new maplibregl.NavigationControl(), 'top-left');
    app.mapCore.addControl(new maplibregl.ScaleControl({ unit: "imperial" }));

    new maplibregl.Marker().setLngLat(coords).addTo(app.mapCore);

    // disable map zoom when using scroll
    app.mapCore.scrollZoom.disable();
}

  handleScroll = (e) => {
      let app = this;
      let scroll = e.currentTarget.pageYOffset;
      let map = app.ref.current.getBoundingClientRect().top;
      let isShowing = app.inViewport(app.ref.current);
      if (scroll > map && isShowing) {
          app.createMap();
          window.removeEventListener('scroll', this.handleScroll);
      }
  }

  inViewport = (el) => {
      let box = el.getBoundingClientRect();
      return (
          box.top >= 0 &&
          box.left >= 0 &&
          box.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          box.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
  }

  componentDidMount () {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount () {
    if (typeof window !== `undefined`) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  render() {
    return (
      <Section id={this.props.id ? this.props.id : null}>
        <Container>
            <Row>
              <Col lg={9}>
                <Styles ref={this.ref} id="map"></Styles>
              </Col>
            </Row>
        </Container>
      </Section>   
    )};
};

export default Map;