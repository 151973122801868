import React, {Component, useRef} from "react";
import styled from "styled-components";
import {Col, Row} from "styled-bootstrap-grid";
import Litepicker from "../../assets/javascript/litepicker";
import breakpoints from "../../styles/breakpoints";
import Icon from "../Core/Icon";
import Container from "../Core/Container";
import SearchForm from "../SearchForm";
import Section from "../Core/Section";

const StickyBlock = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  pointer-events: ${props => props.openedSearch ? "initial" : "none"};

  @media only screen and (min-width: ${breakpoints.lg}) {
    top: 0;
    max-width: ${props => props.blockWidth && `${props.blockWidth}px`};
    position: absolute;
    height: max-content;
    right: 1.25rem;
    width: unset;
    pointer-events: initial;
    box-shadow: rgb(0 0 0 / 30%) -1px 6px 1rem -4px;
  }
`;

const FormFields = styled.div`
  padding: 1.25rem;
  opacity: ${props => props.openedSearch ? "1" : "0"};
  bottom: ${props => props.openedSearch ? "0" : "-18.75rem"};
  display: block;
  z-index: 9;
  transition: .2s;
  position: relative;
  background: #FFFFFF;

  @media only screen and (min-width: ${breakpoints.lg}) {
    opacity: 1;
    bottom: unset;
  }
`;

const Button = styled.button`
  background: ${props => props.white ? "var(--white)" : "var(--secondary)"};
  color: ${props => props.white ? "var(--secondary)" : "var(--white)"};
  cursor: pointer;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  padding: 1.094rem 0.5rem 1.094rem 0.5rem;
  border: 1px solid var(--secondary);
  text-align: center;
  font-weight: 600;
  width: 100%;
  display: ${props => props.openedSearch ? "block" : "none"};
  transition: .3s;
  margin: 0.625rem 0;

  &:hover,
  &:focus {
    border-color: var(--lightenButton);
    background: var(--lightenButton);
    color: var(--white);
    transition: .3s;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

const Dates = styled.div`
  border: 1px solid #B0B0B0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const Line = styled.div`
  height: 55px;
  width: 1px;
  background: #B0B0B0;
  position: absolute;
  left: calc(50% - 1px);
`;

const InputBox = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-bottom: 0;
  max-width: 50%;
  width: 100%;
  height: 55px;

  label {
    display: block;
    position: absolute;
    opacity: ${props => props.isDateEmpty ? "0" : "1"};
    top: 0.688rem;
    left: 0.625rem;
    font-size: 0.75rem;
    font-weight: bold;
  }
  
  input {
    width: 100%;
    box-sizing: border-box;
    background: var(--white);
    box-shadow: none;
    border: 1px solid var(--white);
    outline: none;
    padding: ${props => props.isDateEmpty ? "1.094rem 0.625rem 1.094rem 0.625rem" : "1.688rem 0.625rem 0.5rem 0.625rem"};

    &::placeholder {
      color: ${props => props.error ? "red" : "var(--mediumkgrey)"};
    }
    
    &:focus {
      padding: 1.688rem 0.625rem 0.5rem 0.625rem;

      + label {
        opacity: 1;
      }
      
      &::-webkit-input-placeholder {
        opacity: 0;
      }
    }
  }
`;

const OpenSearchButton = styled.div`
  display: ${props => props.openedSearch ? "none" : "block"};
  z-index: 20;
  background: var(--secondary);
  color: var(--white);
  cursor: pointer;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  padding: 0.938rem 0.5rem 0.938rem 0.5rem;
  border: 1px solid var(--secondary);
  text-align: center;
  font-weight: 600;
  transition: .3s;
  margin: 0 0 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  &:hover,
  &:focus {
    border-color: var(--lightenButton);
    background: var(--lightenButton);
    transition: .3s;
  }
  @media only screen and (min-width: ${breakpoints.lg}) {
    display: none;
    max-width: 17.75rem;
  }
`;

const CloseButton = styled.div`
  display: ${props => props.openedSearch ? "block" : "none"};
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: -1.5rem;
  right: 0;
  cursor: pointer;
  
  svg {
    height: 100%;
    width: 100%;
    fill: var(--secondary);
  }
`;

const Heading = styled.div`
  margin-bottom: 0.688rem;
  
  p {
    font-size: 1rem;
    line-height: 1.188rem;
    margin-bottom: 0.25rem;
  }
`;

const Price = styled.div`
  span {
    display: inline-block;
    font-size: 1.5rem;
  }
  
  p {
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.188rem;
    margin-left: 0.375rem;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.875rem 0 0 0;
  
  span {
    font-size: 1rem;
    line-height: 0.938rem;
  }

  a {
    height: 1.875rem;
    width: 4.875rem;
    margin: 0 0 -0.25rem 0.25rem;
    
    svg {
      height: 100%;
      width: 100%;
    }
  }
`;

class NewFixedForm extends Component {
    constructor(params) {
        super(params)
        this.checkInRef = React.createRef()
        this.checkOutRef = React.createRef()
        this.regionRef = React.createRef()
        this.stickyRef = React.createRef()
    }

    state = {
        error: false,
        date: "",
        checkout: "",
        nights: 2,
        region: this.props.data.property.region,
        openedSearch: false,
        price: this.props.data.property.price,
        startBooking: false,
        STUrl: this.props.data.property.url
    }

    toggleSearch = () => {
        this.setState({openedSearch: !this.state.openedSearch});
    }

    changeDate = (event) => {
        this.setState({date: event.target.value});
    }

    openDatepicker = () => {
        let app = this;
        app.state.datepicker.show();
    }

    openInNewTab = (url) => {
        let win = window.open(url, '_blank');
        win.focus();
    }

    addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

    getNextDayOfWeek = (date, dayOfWeek) => {
        // Code to check that date and dayOfWeek are valid left as an exercise ;)
    
        var resultDate = new Date(date.getTime());
    
        resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    
        return resultDate;
    }

    conductSearch = (event, button) => {
        let app = this;
        event.preventDefault();

        let startBooking = button === "Start booking"; 

        if (this.state.date === "") {
            //if date is empty, autofil to nearest saturday 
            let newDate = app.getNextDayOfWeek(new Date(), 6);
            let newCheckout = app.addDays(newDate, 2)
            app.setState({date : newDate})
            app.setState({checkout : newCheckout})
        }

        app.createUrl(startBooking);
    }

    ajaxRequest = (options, suCallback, erCallback) => {
        let xhr = new XMLHttpRequest(),
            status = typeof options.status !== "undefined" ? options.status : 200;
        xhr.open(options.type, options.url, true);
        xhr.setRequestHeader('Content-Type', options.header);
        if (typeof options.accept !== "undefined") {
            xhr.setRequestHeader('Accept', options.accept);
        }
        if (typeof options.auth !== "undefined") {
            xhr.setRequestHeader('Authorization', options.auth);
        }
        if (typeof options.custom !== "undefined") {
            xhr.setRequestHeader(options.custom[0], options.custom[1]);
        }
        xhr.setRequestHeader('X-CSRF-Token', options.csrf);
        xhr.setRequestHeader('X-Requested-With', "XMLHttpRequest");
        xhr.onreadystatechange = function () {
            var response;
            if (xhr.readyState === 4 && xhr.status === status) {
                response = options.dataType === "json" ? JSON.parse(xhr.responseText) : xhr.responseText;
                if (typeof suCallback !== 'undefined') {
                    suCallback(response);
                }
            } else if (xhr.readyState === 4 && (xhr.status === 500 || xhr.status === 404 || xhr.status === 400)) {
                if (typeof erCallback !== 'undefined') {
                    erCallback(xhr);
                }
            }
        };
        xhr.send(options.data);
    }

    dataLayerPush = (outboundEvent, outbountURL) => {
        if (typeof window !== 'undefined'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({'event': outboundEvent,  'outbound-destination': outbountURL});
        }
    }

    createUrl = (goToProperty) => {
        let app = this;
        let entryPage,
        exitPage;

        if (typeof window !== "undefined") {
            entryPage = "https://www.hottubhideaways.com" + window.sessionStorage.getItem("landingUrl");
            exitPage = window.location.href;
        }

        let url = "https://www.snaptrip.com";
        //booking.com route
        if (app.props.data.property.sendToBCom) {
            app.dataLayerPush("outbound-click", "booking.com property");
    
            setTimeout(function(){ 
                
                if (goToProperty && app.props.data.property.BookingComUrl) {
                    url = `${app.props.data.property.BookingComUrl}?aid=338955&all_sr_blocks=group_adults=2;group_children=0`
                } else if (!goToProperty && app.state.region){
                    url = `https://www.booking.com/searchresults.en.html?ss=${encodeURIComponent(app.props.data.property.region).replace(/%20/g, '+')}&aid=338955`
                } 
    
                app.openInNewTab(url);
    
            }, 100); 
        // st route
        } else {
            let utmParams = `&utm_source=hottubhideaways&utm_medium=referral&utm_campaign=lodge-Unlabelled-landing-${entryPage}-click-${exitPage}`;
            app.dataLayerPush("outbound-click", "snaptrip property");
    
            setTimeout(function(){ 
                
                if (goToProperty && app.state.STUrl) {
                    url = app.state.STUrl + `?check_in_on=${String(app.state.date).replace(/ /g, '%20')}&nights=${app.state.nights}`
                } else if (!goToProperty && app.state.region){
                    url = url + "/searches/custom?search[region]=" + app.state.region + "&search[check_in_on]=" + String(app.state.date).replace(/ /g, '%20') + "&search[minimum_nights]=" + app.state.nights + "&search[sleeps]=1";
                } 
    
                app.openInNewTab(url+utmParams);
    
            }, 100); 
        }
        
    }

    formScroll = () => {
        let app = this;
        let el = document.getElementById('glance');
        let form = app.stickyRef.current;
        let box = el.getBoundingClientRect();

        //get screen width
        let w = window.innerWidth;

        if (w > 1024) {

            if (box.top < 0) {
                form.style.position = 'fixed';
                // this is to position the form correctly to the right of the container
                if (w > 1280) {
                    let diff = w - 1280;
                    form.style.right = ((diff / 2) + 20) + 'px';
                }

                if (this.oldScroll > window.scrollY) {
                    form.style.top = '88px';
                } else {
                    form.style.top = '0';
                }

                this.oldScroll = window.scrollY;

            } else {
                form.style.position = 'absolute';
                form.style.right = '1.25rem';
                form.style.top = '0';
            }
        }
    }

    componentDidMount () {
        let app = this;

        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', this.formScroll);
            window.addEventListener('resize', this.formScroll);

            app.setState({datepicker: new Litepicker({
                    element: app.checkInRef.current,
                    elementEnd: app.checkOutRef.current,
                    singleMode: false,
                    autoRefresh: true,
                    allowRepick: true,
                    minDate: new Date() -1,
                    numberOfColumns: 2,
                    numberOfMonths: 2,
                    maxDays: 29,
                    format: "D MMM YYYY",
                    tooltipText: {
                        one: 'night',
                        other: 'nights'
                    },
                    tooltipNumber: (totalDays) => {
                        return totalDays - 1;
                    },
                    setup: (picker) => {

                        picker.on('preselect', (date1, date2) => {
                            if (date1 && !date2) {
                                app.setState({date: date1.dateInstance.toString().split('00:00:00')[0]});
                                app.setState({checkout: ""});
                            }

                        });

                        picker.on('selected', (date1, date2) => {
                            var timeDiff = Math.abs(date2.dateInstance.getTime() - date1.dateInstance.getTime());
                            var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

                            setTimeout(() => {
                                app.setState({date: date1.dateInstance.toString().split('00:00:00')[0]});

                                if (date1.dateInstance.toString() === date2.dateInstance.toString()) {
                                    var checkOut = new Date(date2.dateInstance);
                                    checkOut.setDate(checkOut.getDate() + 1);
                                    app.setState({checkout: checkOut.toString().split('00:00:00')[0]});
                                    app.setState({nights: numberOfNights + 1});
                                } else {
                                    app.setState({nights: numberOfNights});
                                    app.setState({checkout: date2.dateInstance.toString().split('00:00:00')[0]});
                                }
                            });

                        });

                        picker.on('show', (el) => {
                            //add close button for mobile
                            var cal = picker.ui;
                            cal.insertAdjacentHTML('afterend', '<button class="litepicker-close">Close</button>');

                            //if you've clicked on the check out field, then the check in should remain the same and you should begin by editing the check out
                            if (el.id !== 'checkout' && app.state.date) {
                                picker.clearSelection();
                            }

                            //to do with toggling nav on mobiles
                            nav.dataset.show = true;

                        });

                        picker.on('hide', () => {
                            document.querySelector('button.litepicker-close').remove();
                            nav.dataset.show = false;
                        });

                    }
                })
            });
        }
    }

    componentWillUnmount() {
        if (typeof window !== `undefined`) {
            window.removeEventListener('scroll', this.formScroll);
            window.removeEventListener('resize', this.formScroll);
        }
    }

    render() {

        let price = "Enquire for price",
            isPriceNum;

        // if (isNaN(this.state.price) || this.state.price <= 0 || this.state.price >= 10000) {
        //     isPriceNum = false; 
        //     price = "Enquire for price";
        // } else {
        //     isPriceNum = true; 
        //     price = "£" + (this.state.price * this.state.nights) + " /";
        // }

        let isDateEmpty = this.state.date === "";

        let blockWidth = this.props.containerWidth / 4;

        return (
            <>
                <StickyBlock ref={this.stickyRef} openedSearch={this.state.openedSearch} blockWidth={blockWidth}>
                    <form onSubmit={this.conductSearch}>
                        <FormFields openedSearch={this.state.openedSearch}>
                            <CloseButton onClick={() => this.toggleSearch()} openedSearch={this.state.openedSearch}>
                                <Icon name="icon-cross" />
                            </CloseButton>
                            <Heading>
                            {/* { isPriceNum && <p>From</p> } */}
                                <Price>
                                    <span>Enquire for price</span>
                                    {/* {isPriceNum && <p>{this.state.nights} night{(this.state.nights !== 1) && "s"}</p>} */}
                                </Price>
                            </Heading>
                            <Dates>
                                <InputBox isDateEmpty={isDateEmpty}>
                                    <input id="date" ref={this.checkInRef} name="d8" placeholder="Check in*"
                                           readOnly value={this.state.date} type="text" autoComplete="off"/>
                                    <label htmlFor="date">Check In</label>
                                </InputBox>
                                <Line/>
                                <InputBox isDateEmpty={isDateEmpty}>
                                    <input ref={this.checkOutRef} name="checkout" readOnly
                                           value={this.state.checkout} type="text" autoComplete="off"
                                           id="checkout" placeholder="Check out*"/>
                                    <label htmlFor="checkout">Check Out</label>
                                </InputBox>
                            </Dates>
                            <Button openedSearch={this.state.openedSearch} onClick={() => this.conductSearch(event, "Start booking")}>Check availability</Button>
                            <Button openedSearch={this.state.openedSearch} white={1} onClick={() => this.conductSearch(event, "")}>Show me similar properties</Button>
                            <Footer>
                                <span>Powered by</span>
                                <a href="https://www.snaptrip.com/" target="_blank" rel="noreferrer">
                                    <Icon name="icon-snaptrip" />
                                </a>
                            </Footer>
                        </FormFields>
                    </form>
                </StickyBlock>
                <OpenSearchButton onClick={() => this.conductSearch(event, "Start booking")} openedSearch={this.state.openedSearch}>Check availability</OpenSearchButton>
            </>
        );
    }
}

export default NewFixedForm;