import React, {useEffect, useRef, useState} from "react";
import styled, {keyframes} from "styled-components";
import {Row, Col} from "styled-bootstrap-grid";
import Section from "../Core/Section";
import Container from "../Core/Container";
import {isBrowser} from "../../services/browser";
import breakpoints from "../../styles/breakpoints";

const RatingsContent = styled.div`
  background: var(--lightGrey);
  padding: 1.25rem;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1.25rem;
`;

const H3 = styled.h3`
  margin: 0;
`;

const RatingRow = styled.div`
  position: relative;
  background: var(--silverGrey);
  height: 2.5rem;
  margin-bottom: 1.25rem;
  border-radius: 5px;
  color: var(--white);

  p {
    font-size: 0.875rem;
    margin-left: 0.875rem;
    line-height: 2.5rem;
    font-weight: 500;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media only screen and (min-width: ${breakpoints.lg}){
    p {
      font-size: 1rem;
      margin-left: 1.25rem;
    }
  }
`;

const Average = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0;
  }
`;

const animation = (width) => keyframes`
  0% {
    width: 0;
    opacity: 0.8;
  }
  100% {
    width: ${width}%;
    opacity: 1
  }
`;

const PercentageBar = styled.div`
  background: var(--primary);
  position: absolute;
  border-radius: 5px;
  left: 0;
  top: 0;
  height: 100%;
  animation: ${props => props.isVisible && animation(props.width)} 4s forwards;
  -webkit-animation: ${props => props.isVisible && animation(props.width)} 4s forwards;
  animation-iteration-count: 1;
  animation-delay: .25s;
`;

const Ratings = ({heading, ratings, id}) => {
    const [isVisible, setIsVisible] = useState(false);
    const ratingsLength = ratings.length;
    const sum = ratings.map(o => o.percentage).reduce((a, c) => {
        return a + c;
    });

    const ratingsRef = useRef();

    const scrollHandler = () => {
        if (isBrowser()) {
            const ratingsBox = ratingsRef.current.getBoundingClientRect();
            if ((window.pageYOffset - ratingsBox.height) > ratingsBox.top) {
                setIsVisible(true);
            }
        }
    };

    useEffect(() => {
        if (isBrowser()) {
            window.addEventListener("scroll", scrollHandler);
            scrollHandler();
            return () => {
                window.removeEventListener("scroll", scrollHandler);
            };
        }
    }, []);

    return (
      <Section id={id ? id : null}>
        <Container>
          <Row>
            <Col lg={9}>
              <RatingsContent ref={ratingsRef}>
                <TitleRow>
                  <H3>{heading}</H3>
                  <Average>
                    <h3>{(ratings[0].percentage) / 10}</h3>
                    <span>out of 10</span>
                  </Average>
                </TitleRow>
                {ratings.map((rating, index) => {
                    return (
                      <RatingRow key={index}>
                        <p>
                          {rating.label}
                          {" "}
                          -
                          {" "}
                          {rating.percentage}
                          %
                        </p>
                        <PercentageBar width={rating.percentage} isVisible={isVisible} />
                      </RatingRow>
                    );
                })}
              </RatingsContent>
            </Col>
          </Row>
        </Container>
      </Section>
    );
};

export default Ratings;